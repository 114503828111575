<template>
  <Layout>
    <b-card style="overflow: auto">
      <b-table hover :items="data" :busy="loading" :fields="fields">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(user_id)="row">
          <strong>{{ row.item.user_id.email }}</strong> <br />
          +{{ row.item.user_id.country_code }}-{{
            row.item.user_id.phone_number
          }}
        </template>
        <template #cell(campaign)="row">
          {{ row.item.campaign.company.name }}
        </template>
        <template #cell(evidence)="row">
          <a :href="row.item.evidence" target="_blank">
            <img :src="row.item.evidence" alt="Evidence" style="width: 100px" />
          </a>
        </template>
        <template #cell(createdAt)="row">
          {{ moment(row.item.createdAt).format("LLL") }}
        </template>
        <template #cell(payment)="row">
          <span v-if="row.item.campaign.payment_done" style="color: green"
            >Paid</span
          >
          <span v-else style="color: red"> Not Paid </span>
        </template>
        <template #cell(action)="row">
          <button class="approve-btn" @click="approvePayment(row.item._id)">
            Approve
          </button>
          <br />
          <button class="reject-btn" @click="rejectPayment(row.item._id)">
            Reject
          </button>
        </template>
      </b-table>
      <!-- <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
      
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="updatePage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div> -->
    </b-card>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaign_payment } from "@/config/api/campaign_payment";
export default {
  data() {
    return {
      loading: false,
      data: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      fields: [
        { key: "ref_no", label: "Ref No." },
        { key: "evidence", label: "Evidence" },
        { key: "user_id", label: "User" },
        { key: "campaign", label: "Company" },
        { key: "createdAt", label: "Created" },
        { key: "payment", label: "Payment" },
        { key: "action", label: "Action" },
      ],
    };
  },
  components: {
    Layout,
  },
  created() {
    this.loadAllCampaign();
  },
  methods: {
    loadAllCampaign() {
      const api = campaign_payment.get;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data.bank_payments;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    approvePayment(id) {
      const api = campaign_payment.approve;
      api.id = id;
      this.$bvModal
        .msgBoxConfirm("Are you sure do you want to approve the campaign?", {
          title: "Payment approve",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Payment accepted successfully", {
                  title: "Payment accept",
                  variant: "success",
                  solid: true,
                });
                this.loadAllCampaign();
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Payment approve not carried out, ${err.response.data}`,
                  {
                    title: "Payment approve",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {});
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    rejectPayment(id) {
      const api = campaign_payment.reject;
      api.id = id;
      this.$bvModal
        .msgBoxConfirm("Are you sure do you want to reject the campaign?", {
          title: "Payment reject",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Payment rejected successfully", {
                  title: "Payment reject",
                  variant: "success",
                  solid: true,
                });
                this.loadAllCampaign();
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Payment reject not carried out, ${err.response.data}`,
                  {
                    title: "Payment reject",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {});
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    updatePage(page, size) {
      console.log(page, size);
    },
  },
};
</script>

<style>
.approve-btn,
.reject-btn {
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 2px;
  padding: 4px;
  width: 84px;
  color: #fff;
  font-size: 12px;
}
.approve-btn {
  background-color: #77b977;
}
.reject-btn {
  background-color: #d73131;
}
</style>
